import { IModule, IModuleRest } from "../interfaces/Module";
import { enumTheme, IImage, IImageRest, IImageSize, IImageSizeRest, ISite, ISiteRest } from "../interfaces/Site";

export const transformSiteIn = (data: ISiteRest) => {
    let site: ISite = {
        host: data.host!,
        _id: data._id!,
        modules: [],
        images: data.images,
        colors: data.colors,
        colorScheme: data.colorScheme,
        active: data.active,
        password: data.password,
        fonts: data.fonts,
        theme: data.theme,
        giftRegistryReservations: data.giftRegistryReservations,
        siteGraphic: data.siteGraphic,
        mostRecentSelectedDesignTemplate: data.mostRecentSelectedDesignTemplate
    };

    if (data.activeDate) {
        site.activeDate = data.activeDate;
    }

    if (data.customDomainActive) {
        site.customDomainActive = data.customDomainActive;
    }

    if (data.customDomainActiveDate) {
        site.customDomainActiveDate = data.customDomainActiveDate;
    }

    if (data.customDomainRegistrationsAvailable) {
        site.customDomainRegistrationsAvailable = data.customDomainRegistrationsAvailable;
    }

    if (!site.giftRegistryReservations) {
        site.giftRegistryReservations = [];
    }

    // If old sites without font set
    if (!site.fonts) {
        site.fonts = {
            heading: "Playfair Display",
            text: "Arial",
        };
    }

    // If old sites without theme
    if (!site.theme) {
        site.theme = enumTheme.theme1;
    }

    if (data.mailReceivers) {
        site.mailReceivers = {
            rsvp: data.mailReceivers.rsvp,
            speech: data.mailReceivers.speech,
        };
    } else {
        site.mailReceivers = {};
    }

    if (data.modules && data.modules.length) {
        site.modules = data.modules.map(transformModuleIn);
    }
    return site;
};

const transformModuleIn = (data: IModuleRest) => {
    let module: IModule = {
        module: data.module,
        _id: data._id, // Used in db
        id: data._id!, // Used in client
        menuTitle: data.menuTitle,
        slug: data.slug,
        hideInMenu: data.hideInMenu,
        hide: data.hide,
    };

    module.properties = {};
    if (data.properties && data.properties.length) {
        data.properties.forEach((property) => {
            module.properties[property.key] = property.value;
        });
    }
    return module;
};

interface ITransformOutOptions {
    stripId?: boolean;
}

export const transformSiteOut = (site: ISite, options: ITransformOutOptions = {}) => {
    let data: ISiteRest = {
        modules: [],
        images: [],
        mailReceivers: {
            rsvp: site.mailReceivers!.rsvp,
            speech: site.mailReceivers!.speech,
        },
        fonts: site.fonts,
        theme: site.theme,
        siteGraphic: site.siteGraphic,
        mostRecentSelectedDesignTemplate: site.mostRecentSelectedDesignTemplate
    };

    if (site.colorScheme) {
        data.colorScheme = site.colorScheme;
        data.colors = null;
    } else {
        data.colors = site.colors;
    }

    if (site.images && site.images.length) {
        data.images = site.images.map((image) => {
            return transformImageOut(image, options);
        });
    }
    if (site.modules && site.modules.length) {
        data.modules = site.modules.map((module) => {
            return transformModuleOut(module, options);
        });
    }
    return data;
};

const transformSizesOut = (size: IImageSizeRest, options: ITransformOutOptions): IImageSize => {
    let data: IImageSize = {
        size: size.size,
        fileName: size.fileName,
    };
    if (!(options.stripId && options.stripId === true)) {
        data._id = size._id;
    }
    return data;
};

const transformImageOut = (image: IImage, options: ITransformOutOptions): IImageRest => {
    let data: IImageRest = {
        fileName: image.fileName,
        id: image.id,
        temporary: image.temporary,
        path: image.path,
        originalName: image.originalName,
        cdn: image.cdn,
    };
    if (!(options.stripId && options.stripId === true)) {
        data._id = image._id;
    }
    if (image.sizes && image.sizes.length) {
        data.sizes = image.sizes.map((size) => {
            return transformSizesOut(size, options);
        });
    }
    if (image.imageBeforeEdit?.path) {
        data.imageBeforeEdit = {
            path: image.imageBeforeEdit.path,
            fileName: image.imageBeforeEdit.fileName,
            sizes: image.imageBeforeEdit.sizes?.map((size) => {
                return transformSizesOut(size, options);
            }),
        };
    }
    return data;
};

const transformModuleOut = (module: IModule, options: ITransformOutOptions): IModuleRest => {
    let data: IModuleRest = {
        module: module.module,
        menuTitle: module.menuTitle,
        slug: module.slug,
        hideInMenu: module.hideInMenu,
        hide: module.hide,
    };

    if (!(options.stripId && options.stripId === true)) {
        data._id = module._id;
    }

    data.properties = convertPropertiesToArray(module.properties);
    return data;
};

const convertPropertiesToArray = (properties: any) => {
    let propertiesAsArray: any = [];
    Object.keys(properties).forEach((key) => {
        propertiesAsArray.push({
            key: key,
            value: properties[key],
        });
    });

    return propertiesAsArray;
};
