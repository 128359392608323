import React from "react";
import Helmet from "react-helmet";
import { IError } from "../../../../../App";
import FormWrapper from "../../../../components/form/FormWrapper/FormWrapper";
import TextField from "../../../../components/form/TextField/TextField";
import Loading from "../../../../components/Loading/Loading";
import ShowError from "../../../../components/ShowError/ShowError";
import { IconLock } from "../../../../icons/lock";
import { IconLongArrowRight } from "../../../../icons/longArrowRight";
import { ISite } from "../../../../interfaces/Site";
import { postPublicSiteLogin } from "../../../../services/SiteService";
import { getBrandedSingleCustomerPageName } from "../../../../utils/getBrand";
import styles from "./PublicLogin.module.scss";

interface IProps {
    setSiteInitial: (site: ISite) => void;
    host: string;
}

interface IState {
    whoho: string;
    loading: boolean;
    error: IError | null;
}

export class ViewPublicLogin extends React.Component<IProps, IState> {
    state: IState = {
        whoho: "",
        loading: false,
        error: null,
    };

    doPublicLogin = async (password: string) => {
        this.setState({ loading: true });
        try {
            const site = await postPublicSiteLogin(this.props.host, password);
            this.props.setSiteInitial(site);
        } catch (error) {
            if ((error as any).response.status === 401) {
                this.setState({ error: { text: "Felaktigt lösenord, försök gärna igen." } });
            } else {
                this.setState({
                    error: { text: "Misslyckades att hämta sidan genom inloggning", response: (error as any).response },
                });
            }
        }
        this.setState({ loading: false });
    };

    onChangeTextField = (newValue: string) => {
        this.setState({
            whoho: newValue,
        });
    };

    welcomeMe = () => {
        this.doPublicLogin(this.state.whoho);
    };

    render() {
        return (
            <>
                <ShowError error={this.state.error}></ShowError>
                <Helmet>
                    <title>{getBrandedSingleCustomerPageName()}</title>
                </Helmet>
                {this.state.loading === true && <Loading fixed={true} />}
                <div className={styles.wrapper}>
                    <div className={styles.innerWrapper}>
                        <FormWrapper submit={this.welcomeMe.bind(this)}>
                            <div className={styles.lockIcon}>
                                <IconLock />
                            </div>
                            {/* Instead of using password as name/id we use whoho too try to avoid atuomatic brute force attacks */}
                            <TextField
                                autofocus={true}
                                required={true}
                                name="whoho"
                                id="whoho"
                                onChangeCallback={this.onChangeTextField}
                            ></TextField>
                            <div className={styles.submitButtonWrapper}>
                                <button className={styles.submitButton}>
                                    <IconLongArrowRight />
                                </button>
                            </div>
                        </FormWrapper>
                    </div>
                </div>
            </>
        );
    }
}
